h2.ui.header.compare-header {
  font-size: 22px;
}
.ui.fixed.sticky.top .compare-header {
  border-radius: 15px;
  box-shadow: 0px 2px 6px 4px rgba(83, 84, 84, 0.3);
  display: inline-block;
  margin: auto;
  padding: 15px 20px;
  background: white;
  z-index: 1000;
  font-size:18px;
}

.compare-arrow {
  position: absolute;
  left: 50%;
  margin-left: -12px !important;
  top: 40px;
}

.stuck-compare-arrow.compare-arrow{
  position: fixed;
  top: 50vh;
  margin-top: -12px !important;
}