html {
	height: 100%;
}

body {
	height: 100%;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	letter-spacing: -0.65px;
	color: #484848;
}

#root {
	height: 100%;
}

/************************
				General
*************************/
.bold {
	font-weight: 700 !important;
}

.overflow-y-initial {
	overflow-y: initial !important;
}

hr {
	color: #ababab;
}

/*************************
				Clicakbles
**************************/
a,
.linky-text {
	cursor: pointer;
	text-decoration: none;
	color: #484848;
}

a:hover,
.linky-text:hover {
	color: #888;
}

.clickable {
	cursor: pointer;
}

.hover-lighten {
	opacity: 0.7;
}

/*************************
				Headers
**************************/
h1,
h2,
h3,
h4,
h5,
h6,
.t1,
.t2 {
	letter-spacing: -0.65px;
	line-height: 1.5;
	margin-top: 0px;
	margin-bottom: 0px;
	font-weight: 700;
}

h1 {
	font-size: 64px;
}

h2 {
	font-size: 56px;
}

h3 {
	font-size: 48px;
}

h4 {
	font-size: 40px;
}

h5 {
	font-size: 32px;
}

h6 {
	font-size: 24px;
}

.t1 {
	font-size: 20px;
}

.t2 {
	font-size: 17px;
}

@media (max-width: 768px) {
	h1 {
		font-size: 48px;
	}

	h2 {
		font-size: 40px;
	}

	h3 {
		font-size: 32px;
	}

	h4 {
		font-size: 28px;
	}

	h5 {
		font-size: 24px;
	}

	h6 {
		font-size: 18px;
	}

	.t1 {
		font-size: 16px;
	}

	.t2 {
		font-size: 14px;
	}
}

/*************************
						List
**************************/
ul {
	list-style: none;
}

li {
	line-height: 2.29;
	letter-spacing: -0.5px;
}

.ul-flat {
	list-style-type: none;
}

.ul-flat li {
	display: inline-block;
}

.vertical-align-middle {
	vertical-align: middle;
}

.vertical-align-middle label,
.vertical-align-middle div {
	vertical-align: middle;
}

/***********************
					Hover
************************/
.hover {
	cursor: pointer;
}
.hover-menu:hover .hover-menu-text {
	color: #ddd !important;
	/* font-weight: 400 !important;
  border-radius: 10px !important;
  box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.2) !important */
}

.hover-menu:hover .hover-menu-sub {
	color: #ccc !important;
	/* font-weight: 400 !important;
  border-radius: 10px !important;
  box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.2) !important */
}

.hover-darkgray:hover {
	color: #999 !important;
}

.selected-tab {
	color: #2d5ee6 !important;
}

.hover-blue:hover {
	color: #0d8eff !important;
}

.hover-lightblue-back:hover {
	background: rgb(28, 149, 255) !important;
	color: white !important;
}

.hover-lightgray-back:hover {
	background: #f5f5f5 !important;
}

.hover-visible-child {
	display: none !important;
}

.hover-visible-parent:hover .hover-visible-child {
	display: block !important;
}

.hover-border-input input{
	border: none !important;
	font-weight: 600 !important;
}

.hover-border-input:hover input,
.hover-border-input input:focus {
	border: 1px solid #eaeaea !important;
	font-weight: 400 !important;
}

.ui.table thead th {
	padding: 0.92857143em 0 !important;
}

.hover-blue-border:hover {
	/* border: 1px solid #2D5EE6 !important; */
	box-shadow: rgba(39, 134, 223, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.2) 0px 2px 6px !important;
}

.hover-blue-border:focus-within {
	/* border: 1px solid #2D5EE6 !important; */
	box-shadow: rgba(39, 134, 223, 0.3) 0px 0px 0px 1px, rgba(15, 15, 15, 0.3) 0px 2px 10px !important;
}

.hover-lightblue-border:hover {
	box-shadow: rgba(26, 145, 224, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.2) 0px 2px 6px !important;
}

.hover-lightblue-border:focus-within {
	/* border: 1px solid rgba(45, 94, 230, 0.616) !important; */
	box-shadow: rgba(26, 145, 224, 0.3) 0px 0px 0px 1px, rgba(15, 15, 15, 0.3) 0px 2px 10px !important;
}

.link-like-text {
	color: #5890f2;
}

.link-like-text:hover {
	color: #196dff;
}

.hover-raised:hover {
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2) !important;
}

.hover-to-more .ellipsis-more-button {
	opacity: 0;
}

.hover-to-more:hover .ellipsis-more-button {
	opacity: 1;
}

.svg-icon {
	vertical-align: middle;
}

.main-side-menu .svg-icon svg path {
	opacity: 0.4;
}

.main-side-menu .item:hover .svg-icon svg path,
.main-side-menu .svg-icon.active svg path {
	opacity: 1;
}

.main-side-menu .active.item .active-marker {
	position: absolute;
	right: -4px;
	top: 50%;
	margin-top: -20px;
	width: 10px;
	border-left: solid 6px white;
	height: 40px;
	border-radius: 6px;
}

/**************************
			Common Widgets
***************************/
.sticky-additional {
	display: none;
}

.ui.fixed.sticky.top .sticky-additional {
	display: block;
	font-size: 18px;
}

.ui.fixed.sticky.top .sticky-header {
	/* position: fixed; */
	z-index: 300;
	/* top: 50px; */
	left: 0px;
	box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
	/* width: 100vw; */
	background: white;
}

.ui.fixed.sticky.top .sticky-header .sticky-header-text {
	font-size: 16px;
	margin-top: 3px;
}

.ui.fixed.sticky.top .sticky-header .sticky-header-text {
	margin-top: 5px !important;
}

.ui.fixed.sticky.top .sticky-header .sticky-btn-right {
	float: right;
	margin-right: 50px;
}

/**********************************
			 Semantic Menu Override
***********************************/
.ui.secondary.menu.stick-top-menu {
	padding: '0px 20px';
}

.ui.secondary.menu.stick-top-menu .item,
.ui.secondary.menu.stick-top-menu .item.active {
	border-radius: 0px 0px 15px 15px;
	border: 1px solid #eee;
	border-top: none;
	padding: 15px 24px;
	margin: 0px 3px;
	font-weight: 600;
	height: 50px;
}

.ui.secondary.menu.stick-top-menu .item.active {
	background: #3cc6c2;
	color: white;
	border: none;
}

.ui.secondary.menu.stick-top-menu .item.active:hover {
	background: rgb(109, 214, 211);
	color: white;
}

.ui.secondary.menu.secondary-menu-minimal .item {
	color: #5f6368;
}

.ui.secondary.menu.secondary-menu-minimal .item.active {
	color: #0d8eff;
	font-weight: 600;
	background: none;
}

div.ui.secondary.pointing.menu .item {
	color: #5f6368;
}

/**
*  Semantic Search Override
*/

.search-compact .ui.input input {
	padding: 0.5em 0.7em;
}

.search-compact-2 .ui.input input {
	padding: 0.6em 0.7em;
}

.ui.dropdown .menu div.item:hover,
.ui.dropdown .menu div.selected.item {
	background-color: rgba(0, 0, 0, 0.1);
}

.ui.multiple.dropdown > .ui.label {
	background: rgba(0, 0, 0, 0.03);
	padding: 8px 12px;
	margin: 2px;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
	color: #333;
}

/**
*  Semantic Table Override
*/
.white-header tr th {
	background: #ffffff !important;
}

/**
*  Icon
*/
.flip-270-icon {
	transform: scaleX(-1) scaleY(-1);
	-moz-transform: scaleX(-1) scaleY(-1);
	-webkit-transform: scaleX(-1) scaleY(-1);
	-ms-transform: scaleX(-1) scaleY(-1);
}

/******************************
						Input
*******************************/

.ui.form .edit-to-click-input input,
.ui.form .edit-to-click-input textarea {
	padding: 6px !important;
	margin: -3px !important;
	transition: none !important;
	border: none !important;
	background: none;
}

.ui.selection.toggled-input,
.ui.input.toggled-input input,
.input.toggled-input .ui.form textarea {
	padding: 6px !important;
	margin: -3px !important;
}

.ui.selection.toggled-input {
	min-height: 2.3em;
}

.ui.form .edit-to-click-input input:focus,
.ui.form .edit-to-click-input:active input,
.ui.form .edit-to-click-input:hover input,
.ui.form .edit-to-click-input textarea:focus,
.ui.form .edit-to-click-input:active textarea,
.ui.form .edit-to-click-input:hover textarea {
	padding: 5px !important;
	border: 1px solid #ccc !important;
}

/**
*     React DatePicker Override
*/

.react-datepicker {
	font-size: 1em;
}
.react-datepicker-popper {
	width: 400px;
}
.react-datepicker__header {
	padding-top: 0.8em;
}
.react-datepicker__month {
	margin: 0.4em 1em;
}
.react-datepicker__year-select,
.react-datepicker__month-select {
	font-size: 1.1em;
}
.react-datepicker__day-name,
.react-datepicker__day {
	width: 1.9em;
	line-height: 1.9em;
	margin: 0.166em;
	font-size: 1.1em;
}
.react-datepicker__current-month {
	font-size: 1em;
}
.react-datepicker__navigation {
	top: 1em;
	line-height: 1.7em;
	border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
	border-right-color: #ccc;
	left: 1em;
}
.react-datepicker__navigation--next {
	border-left-color: #ccc;
	right: 1em;
}

.react-datepicker__time-list-item {
	font-size: 1.1em;
	margin: 0.177em;
}

.full-width-wrapper .react-datepicker-wrapper {
	width: 100%;
}

.full-width-wrapper .react-datepicker-wrapper .react-datepicker__input-container {
	width: 100%;
}

.tight-input .react-datepicker__input-container input {
	padding: 0.5em !important;
	padding-left: 40px !important;
	border-radius: 10px;
}

.input .react-datepicker__input-container input {
	padding-left: 40px !important;
}


.react-datepicker__time-container,
	.react-datepicker__time,
	 .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
		width: 110px;
	}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item {
	height: 40px;
}

/* semantic-ui-react overrides */
/* menu  */

/* .ui.secondary.menu .dropdown.item > .menu,
.ui.text.menu .dropdown.item > .menu {
  color: red;

} */

/******************************
				Big Calendar Override
*******************************/
.rbc-month-view {
	border: none;
}

.rbc-header {
	padding-top: 10px;
	padding-bottom: 20px;
	font-size: 14px;
	color: #767676;
	font-weight: 300;
	border: none;
}

.rbc-header + .rbc-header {
	border-left: none;
}

.rbc-day-bg {
	cursor: pointer;
}

.rbc-day-bg:hover {
	background: #f9ffff;
}

.rbc-day-bg + .rbc-day-bg {
	border-left: 1px solid #e9eced;
}

.rbc-month-row + .rbc-month-row {
	border-top: 1px solid #e9eced;
}

.rbc-date-cell {
	padding: 10px;
	font-weight: 300;
	font-size: 14px;
	color: #767676;
}

.rbc-off-range-bg {
	background: #fafafa;
}

.rbc-date-cell.rbc-off-range {
	color: #bcbcbc;
}

.rbc-day-bg.rbc-today {
	background-color: rgba(0, 0, 0, 0);
}
.rbc-date-cell.rbc-now.rbc-current a:first-child {
	color: #389fa2;
	font-weight: 700;
}

.rbc-event {
	margin: 1px 3px;
	width: auto;
	padding: none;
	border: none;
	background: none;
	height: 30px;
}


.rbc-event:hover {
	opacity: 0.4;
}

.rbc-overlay .rbc-evnt {
	margin: 3px;
}


/** 프린트 용 */
.printed-preview table,
.printed-preview table tr,
.printed-preview table td,
.printed-preview table th,
.printed-preview .chart .segment
{
	border-color: #333 !important;
}