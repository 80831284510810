.ExportDataPasswordModal__container {
	text-align: center;
}

.ExportDataPasswordModal__wrapper {
	text-align: center;
	margin-bottom: 20px;
}

.ExportDataPasswordModal__forgot {
	margin-top: 20px;
}

.ExportDataPasswordModal__wrapper i {
	margin-left: 20px;
}

.ExportDataPasswordModal__btnWrapper {
	margin-bottom: 30px;
	margin-top: 20px;
}

.ExportDataPasswordModal__input {
	width: 250px;
}
