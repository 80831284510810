.hover-underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.btn-no-border {
  box-shadow: none !important;
}

.home-sign-in:hover {
  background: #2db1e2 !important;
}

.home-view-more:hover {
  background: rgb(114, 68, 210) !important;
}

/* ResearchIssueQuestion */
.toggle {
  margin-bottom: 40px;
}
.issue_subject input {
  /* display: none; */
  /* width: 0; */

  /* z-index: 2; */
}

.issue_subject input[type='radio'] + label,
.issue_subject input + label {
  padding: 15px 25px;
  background: #f3214f;
  color: #fff;
  border: 1px solid #2196f3;
  /* margin-left: -px; */
  margin-bottom: 20px;
  margin-right: 10px;
  border: 1px solid transparent;
}
.issue_subject input[type='radio']:checked + label,
.issue_subject input:checked + label {
  padding: 15px 25px;
  background-color: #2196f3;
  border: 1px solid #2196f3;
  color: #fff;
  margin-bottom: 20px;
  margin-right: 10px;
  border: 1px solid transparent;
}
/* ResearchIssueQuestion */

@media screen and (max-width: 940px) {
  .ui.grid > [class*='three column'].row > .column.mobile-whole {
    width: 100% !important;
  }
}

.markdown-body.markdown-small h1 {
  font-size: 18px;
}

.markdown-body.markdown-small h2 {
  font-size: 16px;
}

.markdown-body.markdown-small h3 {
  font-size: 15px;
}

.markdown-body.markdown-small h4 {
  font-size: 14px;
}
