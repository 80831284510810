/** Custom CSS for Chart **/
.section-title:hover .section-title-label {
	/* background-color: rgba(0,0,0,0.1) */
	opacity: 0.8;
}

/**
*   Section
*/

/**
*   DataFieldGroup
*/
.ui.styled.accordion .content {
	display: none;
}

.ui.styled.accordion .content.active {
	display: block;
}

/**
*   DataField
*/
.df-wrap {
	display: block !important;
}
/*
.df-label-wrap {
  display:block !important;
  margin-bottom: 12px;
  white-space: nowrap;
} */

.df-container {
	/* margin-top: 8px; */
	/* padding: 16px; */
	padding: 5px;
	border-radius: 2px;
}

.df-container.df-grid-cell {
	margin-top: 0;
	padding: 0;
}

.df-container .data-field-edit {
	position: absolute;
	top: 5px;
	right: 3px;
}

.df-grid-cell-indi .data-field-edit {
	top: 3px;
}

.ui.input.text-only-edit input:not(:focus) {
	background: none !important;
	border: none !important;
	font-weight: 600;
}
/*
.df-container:hover,
.df-container:focus {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
} */
/*
.df-label:hover {
  background: #aaa !important;
} */
/*
.df-label-wrap-inline {
  margin-top: 10px !important;
}

.df-wrap-inline .df-label-wrap {
  margin-top: -1px !important;
} */

.df-container .react-datepicker-wrapper,
.df-container .react-datepicker__input-container,
.df-container .react-datepicker__input-container input {
	width: 100%;
}

.df-container .rangeslider {
	flex: 1;
}

div .ui.basic.background-white {
	background: white !important;
}

.df-container .ui.input input {
	padding: 0.45em 0.7em;
}

.df-container .ui.button.dropdown {
	padding: 0.6em 1em;
}

.df-container .ui.button.dropdown {
	padding: 0.6em 1em;
}

.df-container-change {
	border-radius: 10px;
	padding: 6px;
	margin: -3px -6px;
}

.df-container-added {
	background: rgb(212, 245, 212);
}

.df-container.df-container-removed {
	border: 1px solid #f3a4a4;
}
.df-container.df-container-added {
	border: 1px solid #41d436;
}

.df-container-removed {
	background: rgb(255, 231, 231);
}

.df-container-sign {
	position: absolute;
	right: 10px;
	top: 10px;
	font-weight: 600;
	font-size: 12px;
}

.df-container-sign.df-container-added {
	color: green !important;
}

.df-container-sign.df-container-removed {
	color: red !important;
}

.df-number input {
	flex: 1 !important;
	min-width: 20px !important;
}

/**
*  Edit Dropdown
*/
.df-container .ui.button .menu {
	opacity: 1;
}

.data-field-edit .data-field-edit {
	z-index: 350;
}

/**
*  Grid Options
*/

.chart-grid {
	padding: 8px;
}

.chart-grid .ui.table th {
	padding: 0px 15px !important;
}
.chart-grid table.ui.table td {
	padding: 0.43em 0.5em;
}
.chart-grid .ui.table tfoot th {
	padding: 0.3em !important;
	border-top: 1px solid rgba(180, 207, 235, 0.15);
}

.chart-grid .df-wrap {
	margin-top: 0px !important;
}

.chart-grid .df-number.no-unit input {
	position: relative;
}

.chart-grid .options-dropdown {
	min-width: 0px !important;
}

.chart-grid .df-number.single-unit .label {
	position: absolute;
	right: 1px;
	top: 1px;
	padding: 7.5px 10px !important;
}

.chart-grid .df-checkbox {
	display: table;
}

.chart-grid .df-checkbox .ui.checkbox {
	display: table-cell;
	vertical-align: middle;
	margin-bottom: 0px !important;
}

.chart-grid .df-container-change {
	margin: 0px;
}

.ui.table.chart-grid .chart-grid-row:hover {
	background: white;
}

.chart-grid .chart-grid-row .option-button {
	opacity: 0;
	transition: opacity 0.3s;
	background: none;
	border: none;
	box-shadow: none;
}

.chart-grid .chart-grid-row .option-button {
	margin: 0;
}

.chart-grid .chart-grid-row:hover .option-button,
.chart-grid .chart-grid-row .active .option-button {
	opacity: 1;
}

.chart-grid .chart-grid-row .option-button:hover,
.chart-grid .chart-grid-row .popup.active .option-button {
	background: white;
	border: 1px solid #eaeaea !important;
}

/**
*  Multiple Search
*/
.multiple.search.selection.dropdown .icon.search {
	padding: 10px;
	opacity: 0.5;
}

.active.multiple.search.selection.dropdown .icon.search {
	padding: 10px;
	opacity: 1;
}

/**
*   Dropdown Option
*/
.ui.button.dropdown {
	white-space: nowrap;
}

.ui.button.dropdown div.text {
	margin-right: 10px;
}

/**
*    Slider
*/
.rc-slider-disabled {
	background-color: rgba(0, 0, 0, 0);
}

.ui.styled.accordion .result .content .title {
	border: none;
	padding: 0;
	margin: 0;
}

.ui.styled.accordion .result .content {
	display: block;
	margin: -0.14285714em 0 0;
	font-size: 1em;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
}

@media only screen and (max-width: 767px) {
}
